<!--  -->
<template>
  <div align="center" class="historypage">
    <!-- <div id="DomPdf" style="height: 869px; width: 1236px; border: 1px solid #000000"> -->
    <div id="DomPdf" style="height: 1150px; width: 1236px; border: 1px solid #000000">

      <div style="margin: 20px 57px">
        <el-row class="title">
          <el-col :span="6">
            <div class="blank"></div>
          </el-col>
          <el-col :span="12"><span style="text-align: center; display: inline-block; font-size: 20px">
              心电十二导联检测报告
            </span></el-col>
          <el-col :span="6"><img crossorigin="anonymous" style="width: 100px"
              src="../../../../../public/img/logo_xingou.png" /></el-col>
        </el-row>
        <!-- 绘制双横线 -->
        <hr style=" height: 1px;border: none;border-top: 6px double gray; margin-top: 0px; " />
        <el-row class="info" style="height: 10px">
          <el-col :span="4"><span style="font-size: 14px"> 姓名：{{ realName }} </span></el-col>
          <el-col :span="3"><span style="font-size: 14px"> 性别：{{ gender }} </span></el-col>
          <el-col :span="3"><span style="font-size: 14px"> 年龄：{{ age }} </span></el-col>
          <el-col :span="8"><span style="font-size: 14px">
              时间：{{ createTime }}
            </span></el-col>
          <el-col :span="6" ><el-tag style="font-size:16px" >备注：{{ remark }} </el-tag></el-col>

        </el-row>
        <!-- 绘制单横线 -->
        <hr style="border-top: 2px solid gray" />
        <el-row class="info" style="height: 20px">
          <el-col :span="4"><span style="font-size: 14px"> 心率：{{ conclusion.xinlv }}bpm </span></el-col>
          <el-col :span="4"><span style="font-size: 14px"> PR间期: {{ conclusion.pr }}ms </span></el-col>
          <el-col :span="4"><span style="font-size: 14px"> QT间期: {{ conclusion.qt }}ms </span></el-col>
          <el-col :span="4"><span style="font-size: 14px"> QTc间期: {{ conclusion.qtc }}ms </span></el-col>
          <el-col :span="4"><span style="font-size: 14px"> P时限: {{ conclusion.p }}ms</span></el-col>
          <el-col :span="4"><span style="font-size: 14px"> QRS时限: {{ conclusion.qrs }}ms</span></el-col>
        </el-row>

        <div class="wapper">
          <!-- 网格背景  -->
          <svg id="svg" class="svg"></svg>
        </div>
        <!-- 疾病疑似 -->
        <div style="font-size: 13px">疾病疑似度</div>
        <el-descriptions direction="vertical" :column="10" border>
          <el-descriptions-item v-for="item in DiseaseList" :key="item.label" :label="item.label">
            {{ item.value }}
          </el-descriptions-item>
        </el-descriptions>

        <span style="font-size:12px">{{ PageTitle }}</span>

        <div style="text-align: right; font-size: 12px">*提示:报告仅供参考，请结合临床情况</div>
        <div style="text-align:right;font-size:13px">*模型信息: {{ conclusion.method }}</div>
      </div>
    </div>
    <el-button icon="el-icon-d-caret" @click="Page()">切换页面</el-button>
    <el-button type="primary" @click="showMarkPoint()">查看标记点</el-button>
    <el-button type="primary" plain @click="getPdf_horizon('#DomPdf')">下载心电报告</el-button>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  // props传递属性（从checkContent中来）
  props: {
    userId: Number,  // 【其他平台】读取的属性
    recordId: String, // 【其他平台】读取的属性
    createTimeId: String, // 【其他平台】读取的属性
    durationId: Number,  // 【其他平台】读取的属性
  },
  components: {},
  data() {
    //这里存放数据
    return {
      uid: this.userId,  // 从props中赋值
      sid: this.recordId, // 从props中赋值
      createTime: this.createTimeId, // 从props中赋值
      duration: this.durationId, // 从props中赋值
      // pdf打印
      htmlTitle: "PDF_Twelve_ECG",
      // 数据定义
      conclusion: {
        pr: 12,
        date: '',
        qt: '',
        qtc: '',
        p: '',
        qrs: '',
        xinlv: '',
        method: '',
      },
      DiseaseList: [
        { label: '窦性心动过速', value: '', unit: '%', name: 'xdgs' },
        { label: '窦性心动过缓', value: '', unit: '%', name: 'xdgh' },
        { label: '窦性心律不齐', value: '-', unit: '%', name: 'xlbq' },
        { label: '房性早搏', value: '-', unit: '%', name: 'fxzb' },
        { label: '交界性早搏', value: '', unit: '%', name: 'jjxzb' },
        { label: '室性早搏', value: '', unit: '%', name: 'sxzb' },
        { label: '室上性心动过速', value: '-', unit: '%', name: 'ssxxdgs' },
        { label: '室性心动过速', value: '', unit: '%', name: 'sxxdgs' },
        { label: '交界性逸搏', value: '-', unit: '%', name: 'fxyb' },
        { label: '室性逸搏', value: '', unit: '%', name: 'sxyb' },
        { label: '房颤', value: '', unit: '%', name: 'fc' },
        { label: '房扑', value: '', unit: '%', name: 'fp' },
        // 三个房室传导阻滞合并-房室传导阻滞
        { label: '房室传导阻滞', value: '', unit: '%', name: 'fscdzz' },
        // { label: 'I度房室传导阻滞', value: '', unit: '%', name: 'Idfs' },
        // { label: 'II度I型房室传导阻滞', value: '', unit: '%', name: 'IIdIfs' },
        // { label: 'II度II型房室传导阻滞', value: '', unit: '%', name: 'IIdIIfs' },
        // 三个室内传导阻滞合并-室内传导阻滞
        { label: '室内传导阻滞', value: '', unit: '%', name: 'sncdzz' },
        // { label: '左束支传导阻滞', value: '', unit: '%', name: 'zszcdzz' },
        // { label: '右束支传导阻滞', value: '', unit: '%', name: 'yszcdzz' },
        // { label: '左前分支传导阻滞', value: '', unit: '%', name: 'zqfz' },
        // 三个预激综合征合并-预激综合征
        { label: '预激综合征', value: '', unit: '%', name: 'yjzhz' },
        // { label: '典型预激综合征', value: '', unit: '%', name: 'dxyjzhz' },
        // { label: '短PR综合征', value: '', unit: '%', name: 'dprzhz' },
        // { label: '变异型预激综合征', value: '', unit: '%', name: 'byxyjzhz' },

        { label: 'ST段抬高', value: '', unit: '%', name: 'sttg' },
        { label: 'ST段压低', value: '', unit: '%', name: 'styd' },
        { label: 'T波倒置', value: '', unit: '%', name: 'tbxf' },
        { label: 'T波高尖', value: '', unit: '%', name: 'tbgdyc' },
        { label: '病理性Q波', value: '', unit: '%', name: 'blxqb' },
        // { label: '', value: '', name: '' },  

      ],
      gender: '',
      age: '',
      realName: '',
      endTime: '',
      MarkPointFlag: false,
      yData: [],
      diseases: [],
      results: [],
      MarkPointFlag: false,
      PointNumber: 6000,
      NextPageFlag: false,
      PageTitle: '第1页',
      remark:'',
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    EndTime() {
      var startTime = +new Date(this.createTime.substring(0, 4),
        this.createTime.substring(5, 7) - 1,
        this.createTime.substring(8, 10),
        this.createTime.substring(11, 13),
        this.createTime.substring(14, 16),
        this.createTime.substring(17, 19));
      var endTime = new Date(startTime + this.duration * 1000)
      let y = endTime.getFullYear();
      let MM = endTime.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = endTime.getDate();
      d = d < 10 ? ('0' + d) : d;
      let h = endTime.getHours();
      h = h < 10 ? ('0' + h) : h;
      let m = endTime.getMinutes();
      m = m < 10 ? ('0' + m) : m;
      let s = endTime.getSeconds();
      s = s < 10 ? ('0' + s) : s;
      // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
      this.endTime = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒
    },
    drawECG() {

      var svg_grid = this.$Snap("#svg");
      svg_grid.selectAll("*").remove();
      // 绘制大方格
      for (var i = 0; i < 66; i++) {
        svg_grid.line(0, 17 * i, 1105, 17 * i).attr({  // 横线
          stroke: "#e03433",
          strokeWidth: 1.2,
        });
        svg_grid.line(17 * i, 0, 17 * i, 850).attr({    // 竖线
          stroke: "#e03433",
          strokeWidth: 1.2,
        });
      }
      // 绘制小方格
      for (var j = 1; j < 325; j++) {
        svg_grid.line(0, 3.4 * j, 1105, 3.4 * j).attr({  // 横线
          stroke: "#e03433",
          strokeWidth: 0.3,
        });
        svg_grid.line(3.4 * j, 0, 3.4 * j, 850).attr({ // 竖线
          stroke: "#e03433",
          strokeWidth: 0.3,
        });
      }
      var t1 = svg_grid.paper.line(119, 20, 136, 20).attr({
        stroke: "#000",
        strokeWidth: 5,
      });
      // 注释
      var t2 = svg_grid.paper.text(119, 15, "5mm");
      var t3 = svg_grid.paper.text(210, 25, this.startTime);
      // var t4 = svg_grid.paper.text(600, 25, "期间：0-10s");
      var t5 = svg_grid.paper.text(750, 25, "增益：10mm/mV");
      var t6 = svg_grid.paper.text(950, 25, "走速：25mm/s");
      var t7 = svg_grid.paper.text(950, 832, this.endTime);
      // 标志
      var index_ecg = ["Ⅰ", "Ⅱ", "Ⅲ", "aVR", "aVL", "aVF", "V1","V2","V3","V4","V5","V6"];
      var leads = ["I", "II", "III", "aVR", "aVL", "aVF", "V1","V2","V3","V4","V5","V6"];
      for (var y = 0; y <= 772; y += 68) {
        var p1 = svg_grid.paper
          .polyline(
            18,
            68 + y,
            34,
            68 + y,
            34,
            34 + y,
            51,
            34 + y,
            51,
            68 + y,
            68,
            68 + y
          )
          .attr({
            fill: "none",
            stroke: "black",
            strokeWidth: 2,
          });
        var p2 = svg_grid.paper.text(53, 88 + y, index_ecg[y / 68]).attr({
          stroke: "black",
          strokeWidth: 0.6,
        });
      }
      // 绘制心电图
      // 心电图坐标零点x0 = 34px，y0 = 68+17*i，一页画10秒，一秒5大格，一大格5小格，一小格3.4px
      // 8导联采样频率500Hz，一秒500个点，一大格100个点，一小格20个点，点与点之间相邻0.17px
      for (var num = 0; num < 12; num++) {
        this.yData = this.ecgData[leads[num]];
        if (!this.NextPageFlag) {
          var yData = this.yData.slice(0, this.PointNumber);  //第一页取点
        } else {
          var yData = this.yData.slice(this.PointNumber, this.yData.length);  //第二页取点
        }

        var yModify = yData.map(function (index) {
          return 68 * (num + 1) - (index / 10000) * 5 * 2 * 3.4;
        });
        var len = yModify.length;
        for (var k = 0; k < len; k++) {
          yModify.splice(2 * k, 0, 68 + 0.17 * k);
        }
        var ecg1 = svg_grid.paper.polyline(yModify).attr({
          fill: "none",
          stroke: "black",
          strokeWidth: 1,
        });
      }
    },

    //结论参数
    _disease(name, unit, result) {
      this.name = name; //疾病名称
      this.result = result
      this.unit = unit; //单位
    },
    getData() {
      this.$http.adornHeader(this.header),
        // 根据每条心电记录的sid获取该条的数据和结论
        this.$http({
          url: this.$http.adornUrl('/personal/personalstaticecg12/get12EcgData'),  // TODO:
          method: 'get',
          params: this.$http.adornParams({ sid: this.sid })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据")
            this.ecgData = data.ecgData
            this.ecgData = JSON.parse(this.ecgData)   //JSON格式转换
            var key = Object.keys(this.ecgData)
            for (var i = 0; i < key.length; i++) {
              console.log('here')
              this.ecgData[key[i]] = JSON.parse(this.ecgData[key[i]])
            }
            // console.log(this.ecgData)
            this.drawECG();
            // this.MarkPointFlag = true   //【查看标记点的开关】
            // this.showMarkPoint();
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg)
          }
        })
      this.$http({
        url: this.$http.adornUrl('/personal/personalstaticecg12/get12ECGConclusion'), // TODO:
        method: 'get',
        params: this.$http.adornParams({ sid: this.sid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看结论")
          console.log( data.conclusionData)
          this.conclusion_tem = data.conclusionData

          // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
          if (this.conclusion_tem[this.conclusion_tem.length - 1] == ',') {
            this.conclusion_tem = this.conclusion_tem.substring(0, this.conclusion_tem.length - 1);
          }
          this.conclusion = JSON.parse(this.conclusion_tem)
          // console.log(this.conclusion)
          if (this.conclusion.method != 0) {
            this.conclusion.method = '深度学习'
          }
          // DiseaseList疑似度赋值
          for (let i = 0; i < this.DiseaseList.length; i++) {
            let name = this.DiseaseList[i]["name"]
            if ((name in this.conclusion)) {
              if (this.conclusion[name] == 'null') // 非空判断
              {
                this.DiseaseList[i]["value"] = "-"
                continue
              }
              this.DiseaseList[i]["value"] = this.conclusion[name] + '%'

            } else if (name == 'sncdzz') {
              let value = Math.max(this.conclusion['zszcdzz'], this.conclusion['yszcdzz'], this.conclusion['zqfz'])
              if (value) {
                this.DiseaseList[i]["value"] = "有(" + value + "%)"
              } else {
                this.DiseaseList[i]["value"] = "无"
              }
            } else if (name == 'yjzhz') {
              let value = Math.max(this.conclusion['dxyjzhz'], this.conclusion['dprzhz'], this.conclusion['byxyjzhz'])
              if (value) {
                this.DiseaseList[i]["value"] = "有(" + value + "%)"
              } else {
                this.DiseaseList[i]["value"] = "无"
              }
            } else if (name == 'fscdzz') {
              let value = Math.max(this.conclusion['Idfs'], this.conclusion['IIdIfs'], this.conclusion['IIdIIfs'])
              if (value) {
                this.DiseaseList[i]["value"] = "有(" + value + "%)"
              } else {
                this.DiseaseList[i]["value"] = "无"
              }
            }
          }

          // 标志点赋值
          this.Rlist = this.conclusion.rlist.split(',')
          this.Qlist = this.conclusion.qlist.split(",")
          this.Slist = this.conclusion.slist.split(",")
          this.S2list = this.conclusion.s2list.split(",")
          this.Plist = this.conclusion.plist.split(",")
          this.Tlist = this.conclusion.tlist.split(",")
          let MarkPointList = [this.Rlist, this.Qlist, this.Slist, this.S2list, this.Plist, this.Tlist]
          for (let i = 0; i < MarkPointList.length; i++) {
            let arr = MarkPointList[i]
            if (arr[arr.length - 1] == '') {
              MarkPointList[i].pop() //删掉空值
            }
          }
          this.MarkPointList = MarkPointList
          // console.log(this.MarkPointList)
          // V1 V5标志点赋值
          var point_name = ["plist", "qlist", "rlist", "slist", "s2list", "tlist"]
          let point_V1_V5 = JSON.parse(this.conclusion.otherleadslist)
          var point_V1 = JSON.parse(point_V1_V5["V1"])
          var point_V5 = JSON.parse(point_V1_V5["V5"])
          this.V1_points = new Array();
          this.V5_points = new Array();
          for (let j = 0; j < point_name.length; j++) {
            // console.log(point_name[j])
            let name = point_name[j]
            if (point_V1[name] === undefined || point_V1[name].length < 2) {

              continue
            }
            // console.log(point_V1[name].substring(0, point_V1[name].length-1).split(","))
            this.V1_points[j] = point_V1[name].substring(0, point_V1[name].length - 1).split(",")
            this.V5_points[j] = point_V5[name].substring(0, point_V5[name].length - 1).split(",")
          }

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
      this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          this.age = data.age
          this.gender = data.gender
          var genderMap = { 0: "女", 1: "男" }
          this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },

    drawMarkPoint() {
      var svg_grid = this.$Snap('#svg')
      // 标记点绘制 q r s s2 p t
      var leads = ["I", "II", "III", "aVR", "aVL", "aVF", "V1","V2","V3","V4","V5","V6"];
      var colorarray = ["Yellow", "LimeGreen", "DeepSkyBlue", "SandyBrown", "DeepPink", "BlueViolet"] // 色谱
      this.MK = []
      for (let num = 0; num < 12; num++) {
        if (num < 6) {
          var list = this.MarkPointList
        } else if (num == 6) {
          var list = this.V1_points  // TODO:
        } else {
          var list = this.V5_points
        }
        for (let i = 0; i < list.length; i++) {
          let MarkPoint = list[i]
          if (MarkPoint == undefined) {
            continue
          }
          var R = MarkPoint.map(function (index) {
            return 68 + 0.17 * index;
          });

          for (let k = 0; k < MarkPoint.length; k++) {
            if (MarkPoint[k] == 0)        // 去掉标记点为0的点
            {
              continue
            }
            if (!this.NextPageFlag) {
              if (MarkPoint[k] < this.PointNumber) { // 第一页的标志点
                this.MK.push(svg_grid.paper.circle(R[k], 68 * (num + 1) - (this.ecgData[leads[num]][MarkPoint[k]] / 10000) * 5 * 2 * 3.4, 2.5).attr({
                  fill: "none",
                  stroke: colorarray[i],
                  strokeWidth: 1.5,
                }));
              }
            } else {
              if (MarkPoint[k] >= this.PointNumber) { // 第二页的标志点
                this.MK.push(svg_grid.paper.circle(R[k] - 6000 * 0.17, 68 * (num + 1) - (this.ecgData[leads[num]][MarkPoint[k]] / 10000) * 5 * 2 * 3.4, 2.5).attr({
                  fill: "none",
                  stroke: colorarray[i],
                  strokeWidth: 1.5,
                }));
              }
            }
          }
        }
      }
    },

    showMarkPoint() {
      this.MarkPointFlag = !this.MarkPointFlag
      // console.log(this.MarkPointFlag)
      if (this.MarkPointFlag == true) {
        this.drawMarkPoint()
      }
      else {
        if (this.MK != undefined) {
          for (let i = 0; i < this.MK.length; i++)
            this.MK[i].remove()
        }
      }
    },
    Page() {
      this.NextPageFlag = !this.NextPageFlag
      if (this.NextPageFlag) {
        this.PageTitle = '第2页'
      } else {
        this.PageTitle = '第1页'
      }

      this.MarkPointFlag = false
      this.drawECG()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$store.state.module.crossPlatform) {
      console.log('yes')
      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
    }
    // this.sid='500'
    console.log(this.$store.state.personal_module.uid);
    if (this.$route.query.staticRecordId != null && this.$route.query.staticRecordId != undefined) {
      this.uid = this.$store.state.personal_module.uid
      this.sid = this.$route.query.staticRecordId
      this.createTime = this.$route.query.staticRecordCreatetime
      this.duration = this.$route.query.staticRecordDuration
      this.remark = this.$route.query.remark
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData();
    this.EndTime();
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}

.wapper {
  position: relative;
  height: 860px;
}

.svg {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 852px;
}

::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 2px 3px;
}

::v-deep .el-descriptions {
  font-size: 12px;
}

::v-deep .el-descriptions-item__label.is-bordered-label {
  color: #656668;
  background: #fafafa;
}
</style>